/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./header";
import Home from "./home";
import Company from "./company";
import Business from "./business";
import Contact from "./contact";
import Footer from "./footer";

function Main() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="ourCompany" element={<Company />} />
        <Route path="ourBusiness" element={<Business />} />
        <Route path="contactUs" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default Main;
