import React from "react";

export default function Footer() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-12 text-center"
          style={{ backgroundColor: "#353535", height: "100px" }}
        >
          <div className="container h-100">
            <div className="row align-items-center footerParent h-100">
              <div className="col-7 col-sm-7 col-md-8 col-lg-8">
                <div className="footerSignUpText">
                  Subscribe to our newsletter for all the latest news.
                </div>
              </div>
              <div className="col-5 col-sm-5 col-md-4 col-lg-4">
                <div className="footerSignUpTitle">
                  <img
                    className=""
                    alt="Chesterton"
                    src="./arr.png"
                    width="30px"
                    style={{
                      filter:
                        "invert(67%) sepia(33%) saturate(1907%) hue-rotate(318deg) brightness(106%) contrast(101%)",
                      marginRight: "15px",
                    }}
                  />
                  Sign Up Now
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12"
          style={{ backgroundColor: "#1D1D1D", height: "200px" }}
        >
          <div className="container h-100">
            <div className="row justify-content-center h-100 align-items-center">
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 text-right">
                <div className="footerContactDetail d-flex">
                  <div>
                    <div className="">Contact us:</div>
                    <div>
                      Excutive Office No.
                      <span style={{ fontFamily: "a" }}>1</span>
                    </div>
                    <div>Jumeirah Creekside Hotel Al Garhoud</div>
                    <div>
                      P.O.BOX: <span style={{ fontFamily: "a" }}>36799</span>
                    </div>
                    <div style={{ fontFamily: "a" }}>
                      Tel:
                      <span style={{ fontFamily: "a" }}> 009714-2308580</span>
                    </div>
                    <div className=" footerEmail mt-3">
                      Email:
                      <br /> trade@chestertontrading.com
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
