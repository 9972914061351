import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Form, InputGroup } from "react-bootstrap";

export default function Company() {
  return (
    <div>
      <Carousel
        // centerMode
        // centerSlidePercentage={100}
        dynamicHeight={false}
        autoPlay={false}
        infiniteLoop={false}
        interval={3000}
        showStatus={false}
        showThumbs={false}
        animationHandler="fade"
        swipeable={false}
      >
        <div>
          <div
            style={{
              backgroundImage: 'url("./company.webp")',
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "600px",
              position: "relative",
              // direction: "rtl",
            }}
          >
            <div
              className="px-3 px-sm-3 px-md-5 position-absolute h-100 w-100 d-flex justify-content-start align-items-center pb-5"
              style={{
                top: "0",
                right: "0",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <div className="text-left pm-carosel-title">Who We Are</div>
                <div className="text-left pm-carosel-detail">
                  We Secure Food Supply From Sustainable Farms.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
      <div className="container">
        <div className="row my-5">
          <div className="col-12 text-left py-5" style={{ color: "#585858" }}>
            <div className="pmCompanyAbouteMiddleTitle">
              Chesterton, A Reliable Food Supplier
            </div>
            <div className="pmCompanyAbouteMiddleText">
              We provide ingredients to some of the major brand in the Middle
              East, Africa and Asia. Our products, such as wheat, corn, sugar,
              palm oil, rice, tea and soybean are key for consumer and
              restaurant brands – and in home kitchens around the world.
            </div>
            <div className="pmCompanyAbouteMiddleText">
              Our experts continue the path to meet ever-changing demand of our
              customers around the world to better understand and align their
              needs
            </div>
            <div className="pmCompanyAbouteMiddleText">
              We provide know-how to farms and help farmers to ensure their
              products are grown organically, buy the products, process, store
              and distribute them across the world.
            </div>
            <div className="pmCompanyAbouteMiddleText">
              Our products are sources from sustainable farms in Germany,
              Russia, India, Ukraine, Kazakhstan Argentina and Brazil.
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            backgroundImage: 'url("/company2.jpg")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            minHeight: "400px",
            backgroundPosition: "center",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-6 col-sm-6 col-md-3 col-lg-3 text-center pmAbouteCompanyFadeImage mb-4">
                <div className="pmAbouteFadeImageTitle">Managed Farms</div>
                <div
                  className="pmAbouteFadeImageText"
                  style={{ fontFamily: "a", fontWeight: "bold" }}
                >
                  11
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-3 col-lg-3 text-center pmAbouteCompanyFadeImage mb-4">
                <div className="pmAbouteFadeImageTitle">Export Quantity/mt</div>
                <div
                  className="pmAbouteFadeImageText"
                  style={{ fontFamily: "a", fontWeight: "bold" }}
                >
                  34,750,000
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-3 col-lg-3 text-center pmAbouteCompanyFadeImage mb-4">
                <div className="pmAbouteFadeImageTitle">Staff</div>
                <div
                  className="pmAbouteFadeImageText"
                  style={{ fontFamily: "a", fontWeight: "bold" }}
                >
                  240
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-3 col-lg-3 text-center pmAbouteCompanyFadeImage mb-4">
                <div className="pmAbouteFadeImageTitle">Year</div>
                <div
                  className="pmAbouteFadeImageText"
                  style={{ fontFamily: "a", fontWeight: "bold" }}
                >
                  13
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-0">
            <iframe
              style={{
                border: "1px solid lightgray",
                borderRadius: "5px",
                minHeight: "450px",
              }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d413.6639706365158!2d55.3406205706369!3d25.242657907873717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d171ef00fd5%3A0x19412aa7e6ac6e72!2sJumeirah%20Creekside%20Hotel!5e0!3m2!1sen!2s!4v1670621759067!5m2!1sen!2s"
              width="100%"
              height="100%"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-left p-4">
            <div className="pmCompanyContactUsTitle my-3">CONTACT US</div>
            <div className="pmCompanyContactUsText mb-4">
              Have a question? We are here to help. Send us a message and we’ll
              be in touch.
            </div>
            <Form method="post" id="user-profile" onSubmit={""} className="">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-4">
                    <Form.Label className="">Name</Form.Label>
                    <InputGroup className="">
                      <Form.Control id="upfn" type="text" />
                    </InputGroup>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-4">
                    <Form.Label className="">Phone</Form.Label>
                    <InputGroup className="">
                      <Form.Control id="upln" type="text" />
                    </InputGroup>
                  </div>
                  <div className="col-12 mb-4">
                    <Form.Label className="">Email</Form.Label>
                    <InputGroup className="">
                      <Form.Control id="upln" type="text" />
                    </InputGroup>
                  </div>
                  <div className="col-12 mb-4">
                    <Form.Label className="">Message</Form.Label>
                    <InputGroup className="">
                      <Form.Control id="upln" type="text" />
                    </InputGroup>
                  </div>
                  <div className="col-12 text-left">
                    <button type="submit" className="pmCompanySubmitButton">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
