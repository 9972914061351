import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function Business() {
  const [topTabSelected, setTopTabSelected] = useState(1);
  const [botTabSelected, setBotTabSelected] = useState(1);
  return (
    <div>
      <Carousel
        // centerMode
        // centerSlidePercentage={100}
        dynamicHeight={false}
        autoPlay={false}
        infiniteLoop={false}
        interval={3000}
        showStatus={false}
        showThumbs={false}
        animationHandler="fade"
        swipeable={false}
        showIndicators={false}
      >
        <div>
          <div
            style={{
              backgroundImage: 'url("/business.jpg")',
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "325px",
              position: "relative",
            }}
          >
            <div
              className="px-3 px-sm-3 px-md-5 position-absolute h-100 w-100 d-flex justify-content-center align-items-center pb-5"
              style={{
                top: "0",
                right: "0",
              }}
            >
              <div>
                <div className="pm-carosel-title text-center">
                  Get Involved!
                </div>
                <div className="pm-carosel-detail-business">
                  Together we can make the difference
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
      {/* ------------------------------ */}
      <div className="container position-relative" style={{ top: "-50px" }}>
        <div className="row">
          <div className="col-1"></div>
          <div
            className="col-2 pmBusinessTopTabItem"
            style={{
              backgroundColor: topTabSelected == 1 ? "#33CB26" : "#f6f6f6",
              color: topTabSelected == 1 ? "#fff" : "#000",
            }}
            onClick={() => setTopTabSelected(1)}
          >
            Wheat
          </div>
          <div
            className="col-2 pmBusinessTopTabItem"
            style={{
              backgroundColor: topTabSelected == 2 ? "#33CB26" : "#f6f6f6",
              color: topTabSelected == 2 ? "#fff" : "#000",
            }}
            onClick={() => setTopTabSelected(2)}
          >
            Corn
          </div>
          <div
            className="col-2 pmBusinessTopTabItem"
            style={{
              backgroundColor: topTabSelected == 3 ? "#33CB26" : "#f6f6f6",
              color: topTabSelected == 3 ? "#fff" : "#000",
            }}
            onClick={() => setTopTabSelected(3)}
          >
            Sugar
          </div>
          <div
            className="col-2 pmBusinessTopTabItem"
            style={{
              backgroundColor: topTabSelected == 4 ? "#33CB26" : "#f6f6f6",
              color: topTabSelected == 4 ? "#fff" : "#000",
            }}
            onClick={() => setTopTabSelected(4)}
          >
            Rice
          </div>
          <div
            className="col-2 pmBusinessTopTabItem"
            style={{
              backgroundColor: topTabSelected == 5 ? "#33CB26" : "#f6f6f6",
              color: topTabSelected == 5 ? "#fff" : "#000",
            }}
            onClick={() => setTopTabSelected(5)}
          >
            Tea
          </div>
          <div className="col-1"></div>
        </div>
      </div>
      {/* ------------------------------ */}
      <div className="container">
        <div
          className="row justify-content-center dirrtl"
          style={{ display: topTabSelected == 1 ? "flex" : "none" }}
        >
          <div className="col-0 col-sm-0 col-md-1"></div>
          <div
            className="col-10 col-sm-10 col-md-4 col-lg-3 p-0"
            style={{
              backgroundImage: 'url("/part0.webp")',
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              minHeight: "200px",
            }}
          ></div>
          <div className="col-10 col-sm-10 col-md-6 col-lg-7 text-left mb-3">
            <div className="pmBussinesTopTabTitle pt-3 pb-2">
              Wheat and whole wheat flours
            </div>
            <div className="pmBussinesTopTabText">
              Our wheat and whole wheat flours can help create everything from
              the densest, heartiest breads to the most delicate and tender
              baked goods, as they offer a wide range of functional benefits
              that can be perfectly matched to your product’s specific needs.
            </div>
          </div>
          <div className="col-0 col-sm-0 col-md-1"></div>
        </div>
        <div
          className="row justify-content-center dirrtl"
          style={{ display: topTabSelected == 2 ? "flex" : "none" }}
        >
          <div className="col-0 col-sm-0 col-md-1"></div>
          <div
            className="col-10 col-sm-10 col-md-4 col-lg-3 p-0"
            style={{
              backgroundImage: 'url("/part2.jpg")',
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              minHeight: "200px",
            }}
          ></div>
          <div className="col-10 col-sm-10 col-md-6 col-lg-7 text-right mb-3">
            <div className="pmBussinesTopTabTitle pt-3 pb-2">
              Maize, a basic yet important grain
            </div>
            <div className="pmBussinesTopTabText">
              At Al Mezhar, we help farmers in Russia and Ukraine to produce the
              high-quality maize suitable for food oil, animal feed and bio
              fuel.
            </div>
          </div>
          <div className="col-0 col-sm-0 col-md-1"></div>
        </div>
        <div
          className="row justify-content-center dirrtl"
          style={{ display: topTabSelected == 3 ? "flex" : "none" }}
        >
          <div className="col-0 col-sm-0 col-md-1"></div>
          <div
            className="col-10 col-sm-10 col-md-4 col-lg-3 p-0"
            style={{
              backgroundImage: 'url("/part1.jpg")',
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              minHeight: "200px",
            }}
          ></div>
          <div className="col-10 col-sm-10 col-md-6 col-lg-7 text-right mb-3">
            <div className="pmBussinesTopTabTitle pt-3 pb-2">
              Sugar, a taste we never forget
            </div>
            <div className="pmBussinesTopTabText">
              Famously, a spoonful of sugar helps the medicine go down. But
              sugar is not just a sweetener. It also acts as a preservative,
              texture modifier, flavoring and coloring agent, fermentation
              substrate and bulking agent in a host of food technology
              applications.
            </div>
          </div>
          <div className="col-0 col-sm-0 col-md-1"></div>
        </div>
        <div
          className="row justify-content-center dirrtl"
          style={{ display: topTabSelected == 4 ? "flex" : "none" }}
        >
          <div className="col-0 col-sm-0 col-md-1"></div>
          <div
            className="col-10 col-sm-10 col-md-4 col-lg-3 p-0"
            style={{
              backgroundImage: 'url("/part3.webp")',
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              minHeight: "200px",
            }}
          ></div>
          <div className="col-10 col-sm-10 col-md-6 col-lg-7 text-right mb-3">
            <div className="pmBussinesTopTabTitle pt-3 pb-2">Basmati Rice</div>
            <div className="pmBussinesTopTabText">
              Rice is mesmerizing aroma, length after cooking, and smooth yet
              delightful taste makes it a must-have meal platter, especially.
              This is why we invest know-how and expertise in Indian Basmati
              rice as a well known rice brand from the US to China.
            </div>
          </div>
          <div className="col-0 col-sm-0 col-md-1"></div>
        </div>
        <div
          className="row justify-content-center dirrtl"
          style={{ display: topTabSelected == 5 ? "flex" : "none" }}
        >
          <div className="col-0 col-sm-0 col-md-1"></div>
          <div
            className="col-10 col-sm-10 col-md-4 col-lg-3 p-0"
            style={{
              backgroundImage: 'url("/part4.webp")',
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              minHeight: "200px",
            }}
          ></div>
          <div className="col-10 col-sm-10 col-md-6 col-lg-7 text-right mb-3">
            <div className="pmBussinesTopTabTitle pt-3 pb-2">
              Unique Tea Taste
            </div>
            <div className="pmBussinesTopTabText">
              We ensure that our tea is handpicked and carefully selected from
              some of the finest tea plantations in India.Thereafter a stringent
              quality controlling process right through the blending, tasting
              and packing process carried out by experienced professionals to
              make sure it meets our customers’ tastes world wide.
            </div>
          </div>
          <div className="col-0 col-sm-0 col-md-1"></div>
        </div>
      </div>
      {/* ------------------------------ */}
      <div className="container-fluid py-5 text-right">
        <div className="row mt-5 dirrtl">
          <div
            className="col-12 col-sm-12 col-md-5 col-lg-5 mb-4"
            style={{
              backgroundImage: 'url("/car2.webp")',
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              minHeight: "400px",
            }}
          ></div>
          <div className="col-12 col-sm-12 col-md-7 col-lg-7">
            <div className="pmBussinesBotTabTitle mb-2">
              From Farm to Kitchen
            </div>
            <div className="pmBussinesBotTabText mb-4">
              We believe nothing comes without co-operation, this is why we help
              farmers to plant, grow, irrigate, harvest, process, store and
              distribute their products.
            </div>
            <div className="row dirrtl">
              <div className="col-8 col-sm-8 col-md-9 col-lg-9 dirltr">
                <div
                  style={{ display: botTabSelected == 1 ? "block" : "none" }}
                >
                  <div className="pmBussinesBotTabTitle2">
                    An on going commitment
                  </div>
                  <div style={{ textAlign: "left", marginBottom: "20px" }}>
                    Farming is a long commitment, it requires experience,
                    know-how and patience.
                  </div>
                  <div style={{ textAlign: "left" }}>
                    At Al Mehzar, we provide R&D know-how, financial support and
                    expertise to farmer to ensure they can cope with ever
                    changing customers' tastes and needs.
                  </div>
                </div>
                <div
                  style={{ display: botTabSelected == 2 ? "block" : "none" }}
                >
                  <div className="pmBussinesBotTabTitle2">
                    Supplying milled grains across the ME, Africa and Asia
                  </div>
                  <div style={{ textAlign: "left", marginBottom: "20px" }}>
                    Consumers’ needs and tastes are changing more rapidly than
                    ever before, which means that we must respond to new food
                    trends and adjust our offerings as needed. That's why Al
                    Mezhar continues to invest in new products to help our B2B
                    customers meet their consumers’ demands.
                  </div>
                  <div style={{ textAlign: "left" }}>
                    We supply milled wheat, corn and rice products to food
                    processors, bakeries, brewers, foodservice companies and
                    snack food producers.
                  </div>
                </div>
                <div
                  style={{ display: botTabSelected == 3 ? "block" : "none" }}
                >
                  <div className="pmBussinesBotTabTitle2">
                    Transport grains across the ME, Asia and Africa
                  </div>
                  <div style={{ textAlign: "left" }}>
                    With our integrated value chain we can make the right
                    connections, linking farmers large and small to the global
                    marketplace. We supply high-quality commodity products to
                    end-customers - from entire nations to small livestock
                    producers.
                  </div>
                </div>
              </div>
              <div className="col-4 col-sm-4 col-md-3 col-lg-3 text-left">
                <div
                  className="pmBussinesbot2TabItems"
                  onClick={() => setBotTabSelected(1)}
                  style={{
                    borderRight:
                      botTabSelected == 1
                        ? "3px solid red"
                        : "3px solid lightgray",
                    color: botTabSelected == 1 ? " red" : "#000",
                  }}
                >
                  Farming
                </div>
                <div
                  className="pmBussinesbot2TabItems"
                  onClick={() => setBotTabSelected(2)}
                  style={{
                    borderRight:
                      botTabSelected == 2
                        ? "3px solid red"
                        : "3px solid lightgray",
                    color: botTabSelected == 2 ? " red" : "#000",
                  }}
                >
                  Milling
                </div>
                <div
                  className="pmBussinesbot2TabItems"
                  onClick={() => setBotTabSelected(3)}
                  style={{
                    borderRight:
                      botTabSelected == 3
                        ? "3px solid red"
                        : "3px solid lightgray",
                    color: botTabSelected == 3 ? " red" : "#000",
                  }}
                >
                  Distribution
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------------ */}
      <div className="text-center my-5">
        <div className="pmBussinesBotDetailTitle mb-3">Meet Our People</div>
        <div className="pmBussinesBotDetailText ">
          We are pleased and ready to learn from everyone who can help us
          improve our job.
          <br />
          Please share your ideas with us.
        </div>
        <button type="submit" className="pmCompanySubmitButton mt-4">
          Contact Us
        </button>
      </div>
    </div>
  );
}
