import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Header(props) {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="">
      <div className="container-fluid pm-header">
        <div className="row align-items-center h-100">
          <div className="col-3" style={{ textAlign: "end" }}>
            <Link to="/" className="">
              <img
                src="./logo.svg"
                alt="Chesterton"
                width="50px"
                className="pm-logo"
              />
            </Link>
          </div>
          <div className="col-9 h-100">
            <div className="d-none d-sm-none d-md-flex d-lg-flex pm-menu ">
              <Link to="/" className="pm-menu-item">
                Home
              </Link>
              <Link to="/ourCompany" className="pm-menu-item">
                Our Company
              </Link>
              <Link to="/ourBusiness" className="pm-menu-item">
                Our Business
              </Link>
              <Link to="/contactUs" className="pm-menu-item">
                Contact Us
              </Link>
            </div>
            <div className="d-flex d-sm-flex d-md-none d-lg-none pm-menu justify-content-end position-relative">
              <img
                alt="Chesterton"
                src="./menu.svg"
                width="50px"
                className="pm-menu-icon"
                onClick={() => setToggle(!toggle)}
              />
              <div
                className={`position-absolute pm-header-drop ${
                  toggle ? "d-block" : "d-none"
                }`}
              >
                <Link to="/" className="pm-menu-item-mobile">
                  Home
                </Link>
                <Link to="/ourCompany" className="pm-menu-item-mobile">
                  Our Company
                </Link>
                <Link to="/ourBusiness" className="pm-menu-item-mobile">
                  Our Business
                </Link>
                <Link
                  to="/contactUs"
                  className="pm-menu-item-mobile"
                  style={{ borderBottom: "none" }}
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
