import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function Home() {
  return (
    <div>
      <Carousel
        // centerMode
        // centerSlidePercentage={100}
        dynamicHeight={false}
        autoPlay
        infiniteLoop
        interval={3000}
        showStatus={false}
        showThumbs={false}
        animationHandler="fade"
        swipeable={false}
      >
        <div>
          <div
            style={{
              backgroundImage: 'url("./car1.webp")',
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "600px",
              position: "relative",
              // direction: "rtl",
            }}
          >
            <div
              className="px-3 px-sm-3 px-md-5 position-absolute h-100 w-100 d-flex justify-content-start align-items-center pb-5"
              style={{
                top: "0",
                right: "0",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <div className="text-right pm-carosel-title">
                  Food is essential.
                </div>
                <div className="text-right pm-carosel-detail">
                  It sustains us, fulfills us and fuels our well-being. We are
                  devoted to that impact every single day.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              backgroundImage: 'url("./car2.webp")',
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "600px",
              position: "relative",
              // direction: "rtl",
            }}
          >
            <div
              className="px-3 px-sm-3 px-md-5 position-absolute h-100 w-100 d-flex justify-content-start align-items-center pb-5"
              style={{
                top: "0",
                right: "0",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <div className="text-right pm-carosel-title">
                  Food is essential.
                </div>
                <div className="text-right pm-carosel-detail">
                  It sustains us, fulfills us and fuels our well-being. We are
                  devoted to that impact every single day.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              backgroundImage: 'url("./car3.jpg")',
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "600px",
              position: "relative",
              // direction: "rtl",
            }}
          >
            <div
              className="px-3 px-sm-3 px-md-5 position-absolute h-100 w-100 d-flex justify-content-start align-items-center pb-5"
              style={{
                top: "0",
                right: "0",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <div className="text-right pm-carosel-title">
                  Food is essential.
                </div>
                <div className="text-right pm-carosel-detail">
                  It sustains us, fulfills us and fuels our well-being. We are
                  devoted to that impact every single day.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
      <div className="container">
        <div className="row my-5">
          <div className="col-12 text-center py-5">
            <div
              className="mb-4"
              style={{
                color: "#792007",
                fontSize: "30px",
                fontFamily: "iranYekanBoldNum",
              }}
            >
              What We Do
            </div>
            <div className="d-flex justify-content-center">
              <div className="pb-4" style={{ borderBottom: "2px solid #000" }}>
                Take a look at some of our past projects.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 col-sm-6 col-md-3 col-lg-3 py-2 px-2 partImageCol">
            <div className="position-relative partImageParent">
              <div
                className="partImage"
                style={{
                  backgroundImage: 'url("./part1.jpg")',
                }}
              ></div>
              <img src="./thumb.jpg" width="100%" alt="Chesterton" />
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-3 col-lg-3 py-2 px-2 partImageCol">
            <div className="position-relative partImageParent">
              <div
                className="partImage"
                style={{
                  backgroundImage: 'url("./part2.jpg")',
                }}
              ></div>
              <img src="./thumb.jpg" width="100%" alt="Chesterton" />
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-3 col-lg-3 py-2 px-2 partImageCol">
            <div className="position-relative partImageParent">
              <div
                className="partImage"
                style={{
                  backgroundImage: 'url("./part3.webp")',
                }}
              ></div>
              <img src="./thumb.jpg" width="100%" alt="Chesterton" />
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-3 col-lg-3 py-2 px-2 partImageCol">
            <div className="position-relative partImageParent">
              <div
                className="partImage"
                style={{
                  backgroundImage: 'url("./part4.webp")',
                }}
              ></div>
              <img src="./thumb.jpg" width="100%" alt="Chesterton" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-5">
          <div className="col-12 text-center py-5">
            <div
              className="mb-4"
              style={{
                color: "#792007",
                fontSize: "30px",
                fontFamily: "iranYekanBoldNum",
              }}
            >
              Why You Should Choose Us
            </div>
            <div className="d-flex justify-content-center">
              <div className="pb-4" style={{ borderBottom: "2px solid #000" }}>
                Discover what makes us the number one choice in construction.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-6 col-sm-6 col-md-3 col-lg-2 text-center">
            <svg
              style={{ color: "#792007", fill: "#792007", width: "80px" }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              id="1845675400"
              class="svg u_1845675400"
              data-icon-name="li_tools"
            >
              <path
                d="M84.9,63.5c-2.3-2.3-5.3-3.6-8.6-3.6c-2.9,0-5.6,1-7.7,2.9L55.8,50l21.3-21.3l0.8,0.8l7.8-7.8l-3.1-3.1l-7.8,7.8l0.8,0.8
	L54.2,48.4L41.4,35.6c1.8-2.2,2.9-4.9,2.9-7.7c0-3.2-1.3-6.3-3.6-8.6c-3-3-7.5-4.2-11.7-3.1l-1.9,0.5l7.3,7.3l-6.3,6.3l-7.3-7.3
	l-0.5,1.9c-1.1,4.2,0.1,8.7,3.1,11.7c2.3,2.3,5.3,3.6,8.6,3.6l0,0c2.9,0,5.6-1,7.7-2.9L52.6,50L38.3,64.3l-3.9-3.9L21.9,72.9
	c-1.3,1.3-1.9,2.9-1.9,4.7c0,1.8,0.7,3.4,1.9,4.7c1.3,1.3,2.9,1.9,4.7,1.9c1.8,0,3.4-0.7,4.7-1.9l12.5-12.5l-3.9-3.9l14.3-14.3
	L67,64.4c-1.8,2.2-2.9,4.9-2.9,7.7c0,3.2,1.3,6.3,3.6,8.6c2.3,2.3,5.3,3.5,8.5,3.5c1.1,0,2.1-0.1,3.2-0.4l1.9-0.5L74,76l6.2-6.3
	l7.3,7.3l0.5-1.9C89.1,71,87.9,66.5,84.9,63.5z M32.1,37.8L32.1,37.8c-2.7,0-5.2-1-7-2.9c-2-2-3-4.7-2.9-7.6l6,6l9.4-9.4l-6-6
	c2.8-0.2,5.6,0.9,7.5,2.9c1.9,1.9,2.9,4.4,2.9,7c0,2.7-1,5.2-2.9,7C37.2,36.8,34.7,37.8,32.1,37.8z M29.7,80.7
	c-1.7,1.7-4.6,1.7-6.2,0c-0.8-0.8-1.3-1.9-1.3-3.1c0-1.2,0.5-2.3,1.3-3.1l10.9-10.9l6.3,6.3L29.7,80.7z M80.2,66.6L70.8,76l6,6
	c-0.2,0-0.4,0-0.6,0c-2.6,0-5.1-1-7-2.9c-1.9-1.9-2.9-4.4-2.9-7c0-2.7,1-5.2,2.9-7c1.9-1.9,4.4-2.9,7-2.9c2.7,0,5.2,1,7,2.9
	c2,2,3,4.7,2.9,7.6L80.2,66.6z"
                id="1962477730"
              ></path>
            </svg>
            <div className="partIconTitle">Ongoing Support</div>
          </div>
          <div className="col-6 col-sm-6 col-md-3 col-lg-2 text-center">
            <svg
              style={{ color: "#792007", fill: "#792007", width: "80px" }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 70 70"
              id="1549007206"
              class="svg u_1549007206"
              data-icon-name="wp-calendar_thin"
            >
              <g id="1718330318">
                <path
                  d="M60,16H48v-4h-2v4H24v-4h-2v4H10v42h50V16z M58,56H12V26h46V56z M58,24H12v-6h10v4h2v-4h22v4h2v-4h10V24z"
                  id="1644190309"
                ></path>
                <path
                  d="M54,30H16v20h38V30z M34,38v4h-7v-4H34z M27,36v-4h7v4H27z M34,44v4h-7v-4H34z M36,44h7v4h-7V44z M36,42v-4h7v4H36z M36,36
		v-4h7v4H36z M18,32h7v4h-7V32z M18,38h7v4h-7V38z M18,44h7v4h-7V44z M52,48h-7v-4h7V48z M52,42h-7v-4h7V42z M52,36h-7v-4h7V36z"
                  id="1320077465"
                ></path>
              </g>
            </svg>
            <div className="partIconTitle">Automatic Updates</div>
          </div>
          <div className="col-6 col-sm-6 col-md-3 col-lg-2 text-center">
            <svg
              style={{ color: "#792007", fill: "#792007", width: "80px" }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              id="1005168621"
              class="svg u_1005168621"
              data-icon-name="li-trophy"
            >
              <path
                d="M76.1,38.7c7-0.6,12.5-6.5,12.5-13.7c0-7.1-5.5-13-12.5-13.6v-0.1H23.8v0.1c-7,0.6-12.5,6.5-12.5,13.6
	c0,7.2,5.5,13.1,12.5,13.7c0.7,13.5,11.5,24.3,25,24.9v22.5H25.1v2.5H75v-2.5H51.2V63.6C64.7,63,75.5,52.2,76.1,38.7z M13.8,25.1
	c0-5.8,4.4-10.5,10-11.1v22.2C18.2,35.5,13.8,30.8,13.8,25.1z M86.2,25.1c0,5.8-4.4,10.5-10,11.1V14C81.8,14.6,86.2,19.3,86.2,25.1z
	 M26.3,37.5V13.8h47.4v23.7c0,13.1-10.6,23.7-23.7,23.7S26.3,50.6,26.3,37.5z"
                id="1139648439"
              ></path>
            </svg>
            <div className="partIconTitle">Outstanding Service</div>
          </div>
          <div className="col-6 col-sm-6 col-md-3 col-lg-2 text-center">
            <svg
              style={{ color: "#792007", fill: "#792007", width: "80px" }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              id="1441712000"
              class="svg u_1441712000"
              data-icon-name="li_diamond"
            >
              <path
                d="M68,31.3H32L22.3,41L50,68.7L77.7,41L68,31.3z M43.3,40.1l6.7-6.7l6.7,6.7H43.3z M57.6,41.9L50,64.6l-7.6-22.8H57.6z
	 M52.1,33.1h13.4l-6.7,6.7L52.1,33.1z M41.2,39.8l-6.7-6.7h13.4L41.2,39.8z M39.1,40.1H25.7l6.7-6.7L39.1,40.1z M40.6,41.9L48,64.2
	L25.7,41.9H40.6z M59.4,41.9h14.8L52,64.2L59.4,41.9z M60.9,40.1l6.7-6.7l6.7,6.7H60.9z"
                id="1390927618"
              ></path>
            </svg>
            <div className="partIconTitle">Best Quality</div>
          </div>
        </div>
      </div>
      <div className="fadeAndGrennPartParet p-2 mt-5">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            backgroundImage: 'url("./car2.webp")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div>
            <div className="fadeImageTitle my-3">Who We Are</div>
            <div className="fadeImageText pb-3">
              Benefit from the expertise of our experienced team.
            </div>
          </div>
        </div>
        <div className="greenAreaParent">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-12 col-md-5 col-lg-4 greenAreaText my-5">
                Our general contracting company oversees construction and
                renovation projects in the residential and commercial sectors.
                Our highly experienced staff has the know-how to ensure our
                clients are completely satisfied with the end result.
              </div>
              <div className="col-12 col-sm-12 col-md-5 col-lg-4 greenAreaText my-5">
                We pride ourselves on providing excellent customer service. We
                are always on hand to offer our support throughout the project,
                while our automatic updates keep clients informed of the
                progress made.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-5">
          <div className="col-12 text-center py-5">
            <div
              className="mb-4"
              style={{
                color: "#792007",
                fontSize: "30px",
                fontFamily: "iranYekanBoldNum",
              }}
            >
              Contact Us
            </div>
            <div className="d-flex justify-content-center">
              <div className="pb-4" style={{ borderBottom: "2px solid #000" }}>
                Get in touch with us for details.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-10 col-lg-10">
            <iframe
              style={{ border: "1px solid lightgray", borderRadius: "5px" }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d413.6639706365158!2d55.3406205706369!3d25.242657907873717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d171ef00fd5%3A0x19412aa7e6ac6e72!2sJumeirah%20Creekside%20Hotel!5e0!3m2!1sen!2s!4v1670621759067!5m2!1sen!2s"
              width="100%"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
