import React from "react";
import { Form, InputGroup } from "react-bootstrap";

export default function Contact() {
  return (
    <>
      <div className="howToContactUs">How to Contact Us</div>
      <div className="container">
        <div className="row dirrtl">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-right dirrtl p-4">
            <Form method="post" id="user-profile" onSubmit={""} className="">
              <div className="container">
                <div className="pmCompanyContactUsTitle mb-4">
                  Send Us a Message
                </div>
                <div className="row dirltr">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-4 ">
                    <Form.Label className="">Name :</Form.Label>
                    <InputGroup className="">
                      <Form.Control id="upfn" type="text" />
                    </InputGroup>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-4">
                    <Form.Label className="">Phone :</Form.Label>
                    <InputGroup className="">
                      <Form.Control id="upln" type="text" />
                    </InputGroup>
                  </div>
                  <div className="col-12 mb-4">
                    <Form.Label className="">Email : </Form.Label>
                    <InputGroup className="">
                      <Form.Control id="upln" type="text" />
                    </InputGroup>
                  </div>
                  <div className="col-12 mb-4">
                    <Form.Label className="">Message :</Form.Label>
                    <InputGroup className="">
                      <Form.Control id="upln" type="text" />
                    </InputGroup>
                  </div>
                  <div className="col-12 text-left">
                    <button type="submit" className="pmCompanySubmitButton">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <div
            className="col-12 col-sm-12 col-md-6 col-lg-6 p-4"
            style={{ textAlign: "left" }}
          >
            If you have any questions about our offer, please contact us by
            filling out the form below and we will get in touch with you
            shortly. Alternatively, you can give us a call or even drop by and
            visit us – we hope to see you soon!
          </div>
        </div>
      </div>
    </>
  );
}
